import React from 'react'
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';

import ConfirmSection from '../ConfirmSection';

const Confirmotp = () => {
    pageTitle('confirm-otp');
  return (
    <div>
    <Section topMd={190} topLg={145} topXl={105} id="appointment">
   <ConfirmSection
   
     imgUrl="/images/home_1/signup.png"
   />
 </Section>
</div>
  )
}

export default Confirmotp;