import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_rtvvae3', 'YOUR_TEMPLATE_ID', form.current, {
        publicKey: 'YOUR_PUBLIC_KEY',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    };
  







  return (
    <form ref={form} onSubmit={sendEmail}>
       <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <div className="row">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Name</label>
          <input
            name='from_name'
            type="text"
            className="cs_form_field"
            placeholder="Enter your name"
          />
          <div className="mb-3" />
        </div>
        
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Mobile Number</label>
          <input
            name='mobile'
            type="text"
            className="cs_form_field"
            placeholder="Enter mobile number"
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            name='from_email'
            type="email"
            className="cs_form_field"
            placeholder="example@gmail.com"
          />
          <div className="mb-3" />
          
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Subject</label>
          <input
            name='subject'
            type="text"
            className="cs_form_field"
            placeholder="Write Subject"
          />
          <div className="mb-3" />
          
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Message</label>
          <textarea
            name='message'
            cols={30}
            rows={10}
            className="cs_form_field"
            placeholder="Write something..."
            defaultValue={''}
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <button className="cs_btn cs_style_1">
            <span>Submit</span>
            <input
            type='submit'
            value="send"/>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </div>
    </div>
    </form>
  );
};

