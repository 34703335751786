import React from 'react'
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import LoginSection from '../LoginSection';

const Login = () => {
    pageTitle('Login | ');
  return (
    <div>
         <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <LoginSection
        
          imgUrl="/images/home_1/login.png"
        />
      </Section>
    </div>
  )
}

export default Login;