import React from 'react'
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import IconBoxStyle9 from '../IconBox/IconBoxStyle9';

    const Coverage = () => {
        pageTitle('Coverage Card');
  return (
    <div>
    <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to <br />ProHealth Medical & Healthcare Center"
        subTitle="Your Partner in Health and Wellness"
    />
    <Section topMd={200} topLg={150} topXl={110}>
    <div className="container">
      <div className="row align-items-center flex-xl-row flex-column-reverse">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-lg-2 col-sm-6">
              <IconBoxStyle9  />
            </div>
            <div className="col-lg-2 col-sm-6">
              <IconBoxStyle9  />
            </div>
            <div className="col-lg-2 col-sm-6">
              <IconBoxStyle9  />
            </div>
            <div className="col-lg-2 col-sm-6">
              <IconBoxStyle9  />
            </div>
            <div className="col-lg-2 col-sm-6">
              <IconBoxStyle9  />
            </div>
            </div>
            </div>
            </div>
            </div>
     </Section>
     </div>
  )
}

export default Coverage;