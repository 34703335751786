import React from 'react'
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';

import SignupSection from '../SignupSection';

const Signup = () => {
    pageTitle('sign-up | ');
  return (
    <div>
    <Section topMd={190} topLg={145} topXl={105} id="appointment">
   <SignupSection
   
     imgUrl="/images/home_1/signup.png"
   />
 </Section>
</div>
  )
}

export default Signup;