import React from 'react';
// import HeroStyle2 from '../Hero/HeroStyle2';
import Section from '../Section';
// import AboutSectionStyle3 from '../Section/AboutSection/AboutSectionStyle3';
// import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4';
// import TestimonialSectionStyle2 from '../Section/TestimonialSection/TestimonialSectionStyle2';
import WorkingProcess from '../Section/WorkingProcess';
import BannerSectionStyle2 from '../Section/BannerSection/BannerSectionStyle2';
// import BlogSectionStyle3 from '../Section/BlogSection/BlogSectionStyle3';
import FaqSectionStyle2 from '../Section/FaqSection/FaqSectionStyle2';
// import AppointmentSectionStyle2 from '../Section/AppointmentSection/AppointmentSectionStyle2';
import BrandsSectionStyle2 from '../Section/BrandsSection/BrandsSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
import PricingSection from '../Section/PricingSection';
import DepartmentSection from '../Section/DepartmentSection';
import Hero from '../Hero';
// import { Link } from 'react-router-dom';
// const departmentData = [
//   {
//     title: 'Emergency Department',
//     iconUrl: '/images/home_2/department_icon_1.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Pediatric Departement',
//     iconUrl: '/images/home_2/department_icon_2.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Obstetrics and Gynecology Department',
//     iconUrl: '/images/home_2/department_icon_3.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Cardiology Department',
//     iconUrl: '/images/home_2/department_icon_4.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Neurology Department',
//     iconUrl: '/images/home_2/department_icon_5.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Psychiatry Department',
//     iconUrl: '/images/home_2/department_icon_6.svg',
//     href: '/departments/department-details',
//   },
// ];
// const testimonialData = [
//   {
//     text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
//     ratingNumber: '5',
//     avatarImgUrl: '/images/home_1/avatar_2.png',
//     avatarName: 'PAULO HUBERT',
//     avatarDesignation: 'New York, USA',
//   },
//   {
//     text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
//     ratingNumber: '4.5',
//     avatarImgUrl: '/images/home_1/avatar_2.png',
//     avatarName: 'PAULO HUBERT',
//     avatarDesignation: 'New York, USA',
//   },
//   {
//     text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
//     ratingNumber: '5',
//     avatarImgUrl: '/images/home_1/avatar_2.png',
//     avatarName: 'PAULO HUBERT',
//     avatarDesignation: 'New York, USA',
//   },
//   {
//     text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
//     ratingNumber: '4.5',
//     avatarImgUrl: '/images/home_1/avatar_2.png',
//     avatarName: 'PAULO HUBERT',
//     avatarDesignation: 'New York, USA',
//   },
// ];
const pricingData = [
  {
    title: 'Essential Care Plan',
    subTitle:
      'Avail this plan as individual, for couple, for family to have maximum benefit.',
      priceTag:
      'Starting from',
    price: '₹1999',
    period: '/year',
    featureTitle: 'Up to 20% discount on doctor’s Consultation, pathology and radiology test, & medicines',

    featureTitle2: '2 Free Tele-Consultation',
 
    featureTitle3: '3 month interest free medical loan up to 1 lac',
    featureTitle4: 'Get annual benefits up to ₹5000/person',

    btnText: 'Get Started',
    btnUrl: '/Essential-Care',
    popular: true,
    Premium: false,
  },
  {
    title: 'Wellness Plus Plan',
    subTitle:
      'Avail this plan as individual, for couple, for family to have maximum benefit.',
      priceTag:
      'Starting from',
    price: '₹3999',
    period: '/year',
    featureTitle: 'Up to 25% discount on doctor’s Consultation, pathology and radiology test, & medicines',

    featureTitle2: '3 Free Tele-Consultation',
 
    featureTitle3: '3 month interest free medical loan up to 1 lac',
    featureTitle4: 'Get annual benefits up to ₹7500/person',
    btnText: 'Get Started',
    btnUrl: '/Wellness-Care',
    popular:false,
  },
  {
    title: 'Health Harmony Bundle',
    subTitle:
      'Avail this plan as individual, for couple, for family to have maximum benefit.',
      priceTag:
      'Starting from',
    price: '₹5999',
    period: '/year',
    featureTitle: 'Up to 40% discount on doctor’s Consultation, pathology and radiology test, & medicines',

    featureTitle2: '5 Free Tele-Consultation',
 
    featureTitle3: '3 month interest free medical loan up to 1 lac',
    featureTitle4: 'Get annual benefits up to ₹12000/person',
 
    btnText: 'Get Started',
    btnUrl: '/Health-Care',
    popular: false,
  },
  {
    title: 'Elite Health Assurance',
    subTitle:
      'Avail this plan as individual, for couple, for family to have maximum benefit.',
      priceTag:
      'Starting from',
    price: '₹9999',
    period: '/year',
    featureTitle: 'Up to 50% discount on doctor’s Consultation, pathology and radiology test, & medicines',

    featureTitle2: '8 Free Tele-Consultation',
 
    featureTitle3: '3 month interest free medical loan up to 1 lac',
    featureTitle4: 'Get annual benefits up to ₹17000/person',
    btnText: 'Get Started',
    btnUrl: '/Elite-Care',
    Premium: true,
  },
];
const workingProcessData = [
  {
    title: 'Buy an OPD package',
    subTitle:
      'Create an account with us, and buy any OPD package as of your need.',
      priceTag:
      'Starting from',
    iconUrl: '/images/home_2/wording_process_icon_1.svg',
    number: '01',
  },
  {
    title: 'Get any medical service',
    subTitle:
      'Visit any doctor or consult online, buy medicines online or offline, have a lab test. Just remember to get the bill.',
    iconUrl: '/images/home_2/wording_process_icon_2.svg',
    number: '02',
  },
  {
    title: 'Upload your bill',
    subTitle:
      'Just open our website (www.healthmatrix.co.in) or app and upload your bill. That’s it!',
    iconUrl: '/images/home_2/wording_process_icon_3.svg',
    number: '03',
  },
  {
    title: 'Wait for 24 Hours',
    subTitle:
      'Leave everything to us! Just wait for 24 hours only',
    iconUrl: '/images/home_2/wording_process_icon_4.svg',
    number: '04',
  },
  {
    title: 'Cashback issued',
    subTitle:
      'Your eligible discounted amount of your bill will credited in your bank account as cashback. Congratulations!',
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '05',
  },
];
// const blogData = [
//   {
//     title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
//     thumbUrl: 'images/home_1/post_1.jpeg',
//     date: 'May 1, 2023',
//   },
//   {
//     title: 'Healthy Eating on a Budget: Tips and Strategies',
//     thumbUrl: 'images/home_1/post_2.jpeg',
//     date: 'May 4, 2023',
//   },
//   {
//     title: 'The Importance of Regular Cancer Screenings and Early Detection',
//     thumbUrl: 'images/home_1/post_3.jpeg',
//     date: 'May 1, 2023',
//   },
// ];
const faqData = [
  {
    title: 'What is healthmatrix.co.in?',
    content:
      ' healthmatrix.co.in is a comprehensive healthcare platform offering subscription-based services for OPD, telemedicine consultations, doctor consultancy, diagnosis bookings, and hospital recommendations.',

  },
  {
    title: 'How does the subscription service work?',
    content:
      'Our subscription service allows users to access a range of healthcare services for a fixed monthly fee. Users can subscribe to the service and enjoy benefits such as unlimited OPD consultations, telemedicine appointments, and more.',
  },
  {
    title: 'What are the benefits of subscribing?',
    content:
      'Subscribers enjoy convenient access to healthcare services from the comfort of their homes, cost savings compared to traditional healthcare visits, priority scheduling for appointments, and personalized healthcare recommendations.',
  },

  {
    title: 'Can I choose my preferred doctor for an OPD consultation?',
    content:
      ' Yes, you can browse through our list of experienced doctors, view their profiles, and select the one that best suits your needs.',
  },
  {
    title: 'What is telemedicine, and how does it work?',
    content:
      ' Telemedicine allows patients to consult with healthcare professionals remotely via video or phone calls. Users can schedule telemedicine appointments through our platform and connect with doctors from anywhere.',
  },
  {
    title: 'Is telemedicine secure and confidential?',
    content:
      '  Absolutely. We prioritize the security and confidentiality of patient information. Our telemedicine platform complies with all relevant data protection regulations to ensure privacy and security.',
  },


];
const CoverageData = [
  {
    title: 'Doctor fees',
    subTitle: 'Visit any doctor or  consult online, we got you covered',
    iconUrl: 'images/home_1/department_icon_1.svg',
    href: '#',
  },
  {
    title: 'Madicines bills',
    subTitle: 'Buy Medicines and share the bill to us to get discount',
    iconUrl: 'images/home_1/department_icon_2.svg',
    href: '#',
  },
  {
    title: 'Lab tests',
    subTitle: 'Visit any Lab of radiology and pathology, we got you covered',
    iconUrl: 'images/home_1/department_icon_3.svg',
    href: '#',
  },
  {
    title: 'Hospitalization',
    subTitle: 'Get hospitalized with our assistance & have an hassle free treatment',
    iconUrl: 'images/home_1/department_icon_4.svg',
    href: '#',
  },
  {
    title: 'Medical loan',
    subTitle: 'No need to worry about finances. Just let us know your need.',
    iconUrl: 'images/home_1/department_icon_5.svg',
    href: '#',
  },

];
const brandData = [
  {
    imgUrl: '/images/brand_1.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_2.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_3.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_4.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_5.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_6.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_7.png',
    imgAlt: 'Brand',
  },
];
export default function HomeStyle2() {
  pageTitle('Home | ');
  return (
    <>
          <Hero
        title="Your Partner in <br /> Health and Wellness"
        subTitle="We are committed to providing you with the best <br /> medical and healthcare services to help you live <br /> healthier and happier."
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="See how we work"
        videoUrl="/how-it-works"
        infoList={[
          {
            title: '95%',
            subTitle: 'Patient satisfaction rating',
            iconUrl: '/images/contact/home1.svg',
          },
          {
            title: '5000+',
            subTitle: 'Patients served annually',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: '10+',
            subTitle: 'Healthcare providers on staff',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        // btnText="Book Now"
        // btnUrl="/appointments"
      />
      {/* <HeroStyle2
        title="Your Partner in Health and Wellness"
        subTitle="We are committed to providing quality care and
        personalized attention to our patients."
        bgUrl="/images/home_2/hero_bg.png"
        imgUrl="/images/home_2/patents.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        btnText=""
        btnUrl="/"
        funfactList={[
          { number: '20+', title: 'Years of experience' },
          { number: '95%', title: 'Patient satisfaction rating' },
          { number: '5000+', title: 'Patients served annually' },
          { number: '10+', title: 'Healthcare providers on staff' },
        ]}
      /> */}
      {/* <Section bottomMd={190} bottomLg={145} bottomXl={105}>
        <AboutSectionStyle3
          titleUp="ABOUT US"
          title="ProHealth is a team of experienced medical professional"
          subTitle="Dedicated to providing top-quality healthcare services. We believe in a holistic approach to healthcare that focuses on treating the whole person, not just the illness or symptoms."
          imgUrl="/images//home_2/about.jpeg"
        />
      </Section> */}
      {/* <Section bottomMd={125} bottomLg={125} bottomXl={85} topMd={185}
        topLg={140}
        topXl={100}>
        <DepartmentSectionStyle4

          sectionTitle="For Your Health"
          sectionTitleUp="OUR DEPARTMENTS"
          data={departmentData}
        />
      </Section> */}
      <Section topMd={185} topLg={150} topXl={110} bottomMd={200}
        bottomLg={150}
        bottomXl={110}>
        <DepartmentSection
          sectionTitle="A COVERAGE YOU ALWAYS NEEDED"
          bgUrl="images/home_1/department_bg.svg"
          data={CoverageData}
        />
      </Section>


      {/* <Section
        className="cs_bg_filed"
        style={{ backgroundImage: `url(/images/home_2/testimonial_bg.svg)` }}
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSectionStyle2
          sectionTitle="Plans just made for you."
          sectionTitleUp="OUR PLANS"
          data={testimonialData}
        />
      </Section> */}
  <Section
    topMd={185}
    topLg={140}
    topXl={100}
    bottomMd={200}
    bottomLg={150}
    bottomXl={110}
  >
    <WorkingProcess
      id="how-it-works"
      sectionTitle="How it Works"
      sectionTitleUp=""
      sectionTitleDown=""
      sectionSubTitle=""
      data={workingProcessData}
    />
  </Section>

      <Section
        className="cs_bg_filed"

        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <PricingSection
          sectionTitle="Plans just made for you."
          sectionTitleUp="OUR PLANS"
          data={pricingData}
          
        />
        
      </Section>
      <Section>

        <BannerSectionStyle2
          bgUrl="/images/home_2/cta_bg.jpeg"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our <br />
          experienced medical professionals today!"
        />
      </Section>
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSectionStyle3
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          sectionTitleDown=""
          sectionSubTitle=""
          data={blogData}
        />
      </Section> */}
      {/* Start FAQ Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <FaqSectionStyle2
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
          btnText="View more"
          btnUrl="/faq"
          
        />  
      </Section>
      {/* End FAQ Section */}
      {/* <Section>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_2/appointment_img.png"
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
        />
      </Section> */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={brandData} />
      </Section>
    </>
  );
}
