import React, { useEffect, useState } from 'react';
import lock from '../../assets/img/lock.svg';
import logo from "../../assets/img/logo.svg";
import razorpay from "../../assets/img/razorpay.svg";
import { Link } from 'react-router-dom';

const Orderform = () => {
  const [formData, setFormData] = useState({
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      country: 'India',
      state: '',
      city: '',
      zipcode: '',
      address: '',
      selectedPlan: '',
      selectedNumber: '1',
      selectedMember: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
      const selectedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
      if (selectedPlan) {
          setFormData((prevFormData) => ({
              ...prevFormData,
              selectedPlan: selectedPlan.name,
              selectedMember: selectedPlan.member,
              selectedDesignation: selectedPlan.designation,
              selectedTitle: selectedPlan.title,
          }));
      }
  }, []);

  const states = [
      'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
      'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand',
      'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
      'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
      'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura',
      'Uttar Pradesh', 'Uttarakhand', 'West Bengal',
      'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu',
      'Lakshadweep', 'Delhi', 'Puducherry', 'Ladakh', 'Jammu and Kashmir'
  ];

  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
  };

  const handlePlanChange = (event) => {
      setFormData({ ...formData, selectedPlan: event.target.value });
  };

  const handleNumberChange = (event) => {
      setFormData({ ...formData, selectedNumber: event.target.value });
  };

  const handleMemberChange = (event) => {
      setFormData({ ...formData, selectedMember: event.target.value });
  };

  const validateFields = () => {
      let newErrors = {};
      Object.keys(formData).forEach(key => {
          if (!formData[key]) {
              newErrors[key] = 'This field is required';
          }
      });
      return newErrors;
  };

  const handlePayment = async () => {
      const newErrors = validateFields();
      if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
      }

      const pay_amount = (formData.selectedTitle * formData.selectedNumber) ;
    const options = {
      key: 'rzp_test_hTlWND9GVAnBxN',
      amount: pay_amount * 100,
      currency: 'INR',
      name: 'Metagate Innovation',
      description: 'At Metagate Innovation, we believe in a future where health knows no boundaries. Founded in 2023, we are a pioneering HealthTech company.',
      image: logo,
      handler: function (response) {
        console.log(response);
      },
      prefill: {
        name: 'Akhilesh Yadav',
        email: 'akhilyaduvanshi1507@gmail.com',
        contact: '8881768607'
      },
      notes: {
        address: 'Corporate Office'
      },
      theme: {
        color: '#19ADB4'
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <div>
    <div className='py-3 rest-of-page'>
        <div className='container-body mt-5 my-5'></div>
      </div>
      <div className='py-4 rest-of-page'>
        <div className='container '>
          <div className='row'>
            <div className='col-md-7 px-5'>
            <div className='card-header billing-info-section'>
                <div className="cs_height_18" />
                <div className="cs_height_18" />
                <h3 className="cs_input_label cs_heading_color">Billing Information</h3>
                <div className="cs_height_18" />
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">First Name</label>
                        <input type="text" name="firstname" className="cs_form_field" placeholder='Enter first name' onChange={handleInputChange} value={formData.firstname} />
                        {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">Last Name</label>
                        <input type="text" className="cs_form_field" name="lastname" placeholder='Enter last name' onChange={handleInputChange} value={formData.lastname} />
                        {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">Phone Number</label>
                        <input type="number" name="phone" className="cs_form_field" placeholder='Enter Phone number' onChange={handleInputChange} value={formData.phone} />
                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">Email</label>
                        <input type="email" name="email" className="cs_form_field" placeholder='Enter email address' onChange={handleInputChange} value={formData.email} />
                        {errors.email && <div className="text-danger">{errors.email}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">Country</label>
                        <input type="text" name="country" className="cs_form_field" onChange={handleInputChange} value={formData.country} />
                        {errors.country && <div className="text-danger">{errors.country}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">State</label>
                        <select name="state" className="cs_form_field" onChange={handleInputChange} value={formData.state}>
                          <option value="" disabled>Select State</option>
                          {states.map((state, index) => (
                            <option key={index} value={state}>{state}</option>
                          ))}
                        </select>
                        {errors.state && <div className="text-danger">{errors.state}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">City</label>
                        <input type="text" name="city" className="cs_form_field" placeholder='Enter City' onChange={handleInputChange} value={formData.city} />
                        {errors.city && <div className="text-danger">{errors.city}</div>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">Zip Code</label>
                        <input type="text" name="zipcode" className="cs_form_field" placeholder='Enter zip-code' onChange={handleInputChange} value={formData.zipcode} />
                        {errors.zipcode && <div className="text-danger">{errors.zipcode}</div>}
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group mb-3'>
                        <label className="cs_input_label cs_heading_color">Address</label>
                        <textarea rows="1" name="address" className="cs_form_field" placeholder='Street address' onChange={handleInputChange} value={formData.address} />
                        {errors.address && <div className="text-danger">{errors.address}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-5 px-5'>
            <div className='card-header order-details-section'>
                <div className="cs_height_18" />
                <div className="cs_height_18" />
                <h3 className="cs_input_label cs_heading_color">Order Details</h3>
                <div className="cs_height_18" />
                <div className='col-md-12'>
                <div className="form-group mb-3">
                                        <label className="cs_input_label cs_heading_color">Selected Plan</label>
                                        <input type="text" name="selectedPlan" className="cs_form_field" readOnly value={formData.selectedPlan} />
                                    </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group mb-3'>
                    <label className="cs_input_label cs_heading_color">Year</label>
    <select
      name="year"
      className="cs_form_field"
      value={formData.selectedNumber}
      onChange={handleNumberChange}
    >
      <option value="1">1</option>
      </select>
                      {errors.selectedNumber && <div className="text-danger">{errors.selectedNumber}</div>}
                    </div>
                  </div>
                  <div className='col-md-6'>
                  <div className="form-group mb-3">
                                        <label className="cs_input_label cs_heading_color"> Members</label>
                                        <input type="text" name="selectedMember" className="cs_form_field" readOnly value={formData.selectedMember} />
                                    </div>
                  </div>
                  <table className="cs_table px-5">
  <tbody>
    <tr>
      <td><h4>{formData.selectedPlan} <br /><h6>({formData.selectedDesignation} | {formData.selectedMember} member{formData.selectedMember > 1 ? 's' : ''})</h6></h4> </td>
      <td><h4>{formData.selectedTitle}</h4></td>
    </tr>
    {/* <tr>
      <td>{formData.selectedDesignation}</td>
      <td><h6>₹0.00</h6></td>
    </tr>
    <tr>
      <td>{formData.selectedMember} member{formData.selectedMember > 1 ? 's' : ''}</td>
      <td><h6>₹0.00</h6></td>
    </tr> */}
    <tr>
      <td>Plan discount - 0%</td>
      <td><h6 className='cs_accent_color'>-₹0.00</h6></td>
    </tr>
    <tr>
      <td>Taxes and fees</td>
      <td><h6>₹0.00</h6></td>
    </tr>
    <tr>
      <td><h4>Total</h4></td>
      <td><h5>{formData.selectedTitle}</h5></td>
    </tr>
  </tbody>
  <p className='cs_accent_color'>Have a coupon code?</p>
</table>
               
                  <div className="col-lg-12">
                
                    <button onClick={handlePayment} className="cs_btn cs_style_1">
                      <span>Confirm Payment</span>
                      <input type='submit' value="send" />
                      <i>
                        <img src="/images/icons/arrow_white.svg" alt="Icon" />
                      </i>
                    </button>
                    <div className="cs_height_18" />
                    <div>
                    <img src={lock} alt='icon' className='icons' /><span>Encrypted and secured payments |</span> <img src={razorpay} alt='icon' className='icons' />
                    </div>
                    <div className="cs_height_18" />
                    <h7>By checking out you agree with our <Link to='/terms'><span className='fw-bold'>Terms of Service </span></Link>and confirm that you have read our<Link to='/policy'><span className='fw-bold'> Privacy Policy.</span></Link> You can cancel recurring payments at any time.</h7>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .cs_table {
            width: 100%;
            border-collapse: collapse;
          }
       
          .cs_table td {
            border: none; /* Remove the border */
            padding: 8px;
            text-align: left;
          }

          .cs_table tr {
            border-bottom: none; /* Remove the bottom border of each row */
          }
          .billing-info-section {
            background-color: white;
            padding: 20px;
            margin-bottom: 20px;
          }
          .order-details-section {
            background-color: white;
            padding: 20px;
            margin-bottom: 20px;
          }
          .rest-of-page {
            background: #F3FEFF;
            padding: 20px;
          }
          .cs_table td:nth-child(1) {
            width: 70%; /* Adjust the width as needed */
          }
          
          .cs_table td:nth-child(2) {
            width: 30%; /* Adjust the width as needed */
            text-align: right;
          }
        `}
      </style>
    </div>
  );
};

export default Orderform;
