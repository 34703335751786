import React from 'react';

import Section from '../Section';

import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';

import FaqSectionStyle2 from '../Section/FaqSection/FaqSectionStyle2';
import BrandsSectionStyle2 from '../Section/BrandsSection/BrandsSectionStyle2';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
// import DepartmentSectionStyle3 from '../Section/DepartmentSection/DepartmentSectionStyle3';
// import DepartmentSectionStyle1 from '../Section/DepartmentSection/DepartmentSectionStyle2';
// import DepartmentSectionStyle5 from '../Section/DepartmentSection/DepartmentSectionStyle5';
// import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4';
// import { useNavigate } from 'react-router-dom';

const featureData = [
    {
      title: 'Discount on Lab Test',
      subTitle:
        'Get access to all radiology and pathology test, and enjoy the heavy discounted rates.',
      iconUrl: '/images/departments/icon_9.svg',
    },
    {
      title: 'Discount on Medicines',
      subTitle:
        'We made expensive medicines, affordable and accessible. Just think of the cure not the cost.',
      iconUrl: '/images/departments/icon_10.svg',
    },
    {
      title: 'Free Tele Consultation',
      subTitle:
        'Get free tele consultation from the experienced and reputed doctors. Never worry about the fees.',
      iconUrl: '/images/departments/icon_11.svg',
    },
    {
      title: 'Interest free medical loans',
      subTitle:
        'Ran out of budget! Need more help? Well, we got you covered. Get interest free medical loan of up to 1 lac for 3 months instantly.',
      iconUrl: '/images/departments/icon_12.svg',
    },
  ];
  
  const doctorData = [
    {
      imgUrl: '/images/departments/individual.png',
      name: 'Essential Care Plan',
      designation: 'For Individual',
      description:
      'Get up to 20% discount on all bills, & benefit <br /> of up  to 5000/person on all categories.',
      title:'1999',
     member:'1',
      availableUrl: '/',
      callUrl: '/',
      chatUrl: '/',
      btnText: 'Buy Now',
      btnUrl: '/order',
    },
    {
      imgUrl: '/images/departments/couple.png',
      name: 'Essential Care Plan',
      designation: 'For Couple',
      description:
      'Get up to 20% discount on all bills, & benefit <br /> of up  to 5000/person on all categories.',
        title:'2999',
        member:'2',
      availableUrl: '/',
      callUrl: '/',
      chatUrl: '/',
      btnText: 'Buy Now',
      btnUrl: '/order',
    },
    {
      imgUrl: '/images/departments/family.png',
      name: 'Essential Care Plan',
      designation: 'For Family',
      description:
        'Get up to 20% discount on all bills, & benefit <br /> of up  to 5000/person on all categories.',
        title:'4999',
        member:' Up to 4',
      availableUrl: '/',
      callUrl: '/',
      chatUrl: '/',
      btnText: 'Buy Now',
      btnUrl: '/order',
    },
 
  ];
  const departmentData = [
    {
      title: 'Buy an OPD package',
      subTitle:
        'Create an account with us, and buy any OPD package as of your need.',
      iconUrl: '/images/icons/01.svg',
      href: '/departments/department-details',
    },
    {
      title: 'Get any medical service',
      subTitle:
        'Visit any doctor or consult online, buy medicines online or offline, have a lab test. Just remember to get the bill.',
      iconUrl: '/images/icons/02.svg',
      href: '/departments/department-details',
    },
    {
      title: 'Upload your bill',
      subTitle:
        'Just open our website (www.healthmatrix.co.in) or app and upload your bill. That’s it!',
      iconUrl: '/images/icons/03.svg',
      href: '/departments/department-details',
    },
    {
      title: 'Wait for 24 Hours',
      subTitle:
        'Leave everything to us! Just wait for 24 hours only',
      iconUrl: '/images/icons/04.svg',
   
      href: '/departments/department-details',
    },
    {
      title: 'Cashback issued',
      subTitle:
        'Your eligible discounted amount of your bill will credited in your bank account as cashback. Congratulations! ',
      iconUrl: '/images/icons/05.svg',
      href: '/departments/department-details',
    },
  ];
  const faqData = [
    {
      title: 'What is healthmatrix.co.in?',
      content:
        ' healthmatrix.co.in is a comprehensive healthcare platform offering subscription-based services for OPD, telemedicine consultations, doctor consultancy, diagnosis bookings, and hospital recommendations.',
  
    },
    {
      title: 'How does the subscription service work?',
      content:
        'Our subscription service allows users to access a range of healthcare services for a fixed monthly fee. Users can subscribe to the service and enjoy benefits such as unlimited OPD consultations, telemedicine appointments, and more.',
    },
    {
      title: 'What are the benefits of subscribing?',
      content:
        'Subscribers enjoy convenient access to healthcare services from the comfort of their homes, cost savings compared to traditional healthcare visits, priority scheduling for appointments, and personalized healthcare recommendations.',
    },
    {
      title: 'Is telemedicine secure and confidential?',
      content:
        '  Absolutely. We prioritize the security and confidentiality of patient information. Our telemedicine platform complies with all relevant data protection regulations to ensure privacy and security.',
    },
    {
      title: 'Can I choose my preferred doctor for an OPD consultation?',
      content:
        ' Yes, you can browse through our list of experienced doctors, view their profiles, and select the one that best suits your needs.',
    },
    {
      title: 'What is telemedicine, and how does it work?',
      content:
        ' Telemedicine allows patients to consult with healthcare professionals remotely via video or phone calls. Users can schedule telemedicine appointments through our platform and connect with doctors from anywhere.',
    },
    {
      title: 'Is telemedicine secure and confidential?',
      content:
        '  Absolutely. We prioritize the security and confidentiality of patient information. Our telemedicine platform complies with all relevant data protection regulations to ensure privacy and security.',
    },
  
  
  ];
  const brandData = [
    {
      imgUrl: '/images/brand_1.png',
      imgAlt: 'Brand',
    },
    {
      imgUrl: '/images/brand_2.png',
      imgAlt: 'Brand',
    },
    {
      imgUrl: '/images/brand_3.png',
      imgAlt: 'Brand',
    },
    {
      imgUrl: '/images/brand_4.png',
      imgAlt: 'Brand',
    },
    {
      imgUrl: '/images/brand_5.png',
      imgAlt: 'Brand',
    },
    {
      imgUrl: '/images/brand_6.png',
      imgAlt: 'Brand',
    },
    {
      imgUrl: '/images/brand_7.png',
      imgAlt: 'Brand',
    },
  ];

  const OpdDetail = () => {
   
  
  return (
    <>
    <BreadcrumbStyle2 />
    <Section topMd={135} topLg={100} topXl={100}>
      <AboutSectionStyle2
        title="Essential Care Plan"
        subTitle="Offers savings on doctor visits, tests, meds, and teleconsultations. Plus, get an interest-free medical loan for up to 1 lakh rupees. A great way to manage healthcare costs!."
        imgUrl="/images/departments/department_img_1.png"
      />
    </Section>

    <Section topMd={170} topLg={145} topXl={90}>
      <FeaturesSectionStyle3
        sectionTitle="Offering"
        sectionTitleUp="WHAT WE ARE"
        data={featureData}
      />
    </Section>
    <Section topMd={200} topLg={150} topXl={100}>
                <TeamSectionStyle3
                    sectionTitle="We Care Everyone"
                    data={doctorData}
                 
                />
            </Section>

    {/* Start Appointment Section */}
    {/* <Section
      topMd={190}
      topLg={145}
      topXl={105}
      bottomMd={190}
      bottomLg={145}
      bottomXl={110}
      id="appointment"
    > */}
      {/* <AppointmentSection
        sectionTitle="Appointment"
        sectionTitleUp="BOOK AN"
        imgUrl="/images/home_1/appointment.jpeg"
      /> */}
    {/* </Section> */}
    <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="How It Works?"
          sectionTitleUp=""
          data={departmentData}
        />
      </Section>
    {/* End Appointment Section */}
    {/* <Section className="cs_footer_margin_0">
      <BannerSectionStyle7
        imgUrl="/images/departments/banner_img_3.png"
        bgUrl="/images/departments/banner_bg_3.svg"
        title="Don’t Let Your Health <br />Take a Backseat!"
        subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
      />
    </Section> */}
       
    
      <FaqSectionStyle2
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
          btnText="View more"
          btnUrl="/faq"
          
        />  
          <Section
  topMd={200}
  topLg={150}
  topXl={110}
  bottomMd={200}
  bottomLg={150}
  bottomXl={110}
>
  <BrandsSectionStyle2 data={brandData} />
</Section>
      
  </>
  )
}
export default OpdDetail;

