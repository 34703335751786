import React from 'react'
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3'
// import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2'
import Section from '../Section'
import { pageTitle } from '../../helpers/PageTitle'
// import FaqSectionStyle2 from '../Section/FaqSection/FaqSectionStyle2'
import PricingSection from '../Section/PricingSection'
import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4'
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4'
import Banner from '../Section/BannerSection'
// import BrandsSection from '../Section/BrandsSection'
import BrandsSectionStyle2 from '../Section/BrandsSection/BrandsSectionStyle2';
// import Spacing from '../Spacing'
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2'


 
    const pricingData = [
      {
        title: 'Essential Care Plan',
        subTitle:
          'Specialized care for seniors. Benefit from tailored assessments, fall prevention, and support for your golden years.',
        price: '₹1999',
        period: '/year',
        featureTitle: 'Up to 20% discount on doctor’s Consultation, pathology and radiology test, & medicines',
    
        featureTitle2: '2 Free Tele-Consultation',
     
        featureTitle3: '3 month interest free medical loan up to 1 lac',
        featureTitle4: 'Get annual benefits up to ₹5000/person',
    
        btnText: 'Get Started',
        btnUrl: '/Essential-Care',
        popular: true,
        Premium: false,
      },
      {
        title: 'Wellness Plus Plan',
        subTitle:
          'Comprehensive healthcare tailored to your needs. Experience unlimited care and exclusive access to wellness events.',
        price: '₹3999',
        period: '/year',
        featureTitle: 'Up to 25% discount on doctor’s Consultation, pathology and radiology test, & medicines',
    
        featureTitle2: '3 Free Tele-Consultation',
     
        featureTitle3: '3 month interest free medical loan up to 1 lac',
        featureTitle4: 'Get annual benefits up to ₹7500/person',
        btnText: 'Get Started',
        btnUrl: '/Wellness-Care',
        popular:false,
      },
      {
        title: 'Health Harmony Bundle',
        subTitle:
          'Specialized care for seniors. Benefit from tailored assessments, fall prevention, and support for your golden years.',
        price: '₹5999',
        period: '/year',
        featureTitle: 'Up to 40% discount on doctor’s Consultation, pathology and radiology test, & medicines',
    
        featureTitle2: '5 Free Tele-Consultation',
     
        featureTitle3: '3 month interest free medical loan up to 1 lac',
        featureTitle4: 'Get annual benefits up to ₹12000/person',
     
        btnText: 'Get Started',
        btnUrl: '/Health-Care',
        popular: false,
      },
      {
        title: 'Elite Health Assurance',
        subTitle:
          'Smile with confidence. Enjoy regular dental check-ups.',
        price: '₹9999',
        period: '/year',
        featureTitle: 'Up to 50% discount on doctor’s Consultation, pathology and radiology test, & medicines',
    
        featureTitle2: '8 Free Tele-Consultation',
     
        featureTitle3: '3 month interest free medical loan up to 1 lac',
        featureTitle4: 'Get annual benefits up to ₹17000/person',
        btnText: 'Get Started',
        btnUrl: '/Elite-Care',
        Premium: true,
      },
    ];
    const departmentData = [
      {
        title: 'Individual',
        iconUrl: '/images/home_2/individual_1.svg',
        href: '/departments/department-details',
      },
      {
        title: 'Couple',
        iconUrl: '/images/home_2/couple_2.svg',
        href: '/departments/department-details',
      },
      {
        title: 'Family',
        iconUrl: '/images/home_2/family_3.svg',
        href: '/departments/department-details',
      },
    
    ];
    const faqData = [
      {
        title: 'What is healthmatrix.co.in?',
        content:
          ' healthmatrix.co.in is a comprehensive healthcare platform offering subscription-based services for OPD, telemedicine consultations, doctor consultancy, diagnosis bookings, and hospital recommendations.',
          
      },
      {
        title: 'How does the subscription service work?',
        content:
          'Our subscription service allows users to access a range of healthcare services for a fixed monthly fee. Users can subscribe to the service and enjoy benefits such as unlimited OPD consultations, telemedicine appointments, and more.',
      },
      {
        title: 'What are the benefits of subscribing?',
        content:
          'Subscribers enjoy convenient access to healthcare services from the comfort of their homes, cost savings compared to traditional healthcare visits, priority scheduling for appointments, and personalized healthcare recommendations.',
      },
      {
        title: 'Is telemedicine secure and confidential?',
        content:
          '  Absolutely. We prioritize the security and confidentiality of patient information. Our telemedicine platform complies with all relevant data protection regulations to ensure privacy and security.',
      },
      {
        title: 'Can I choose my preferred doctor for an OPD consultation?',
        content:
          ' Yes, you can browse through our list of experienced doctors, view their profiles, and select the one that best suits your needs.',
      },
    ];
    const brandData = [
      { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
      { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
      { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
      { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
      { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
      { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
      { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
      { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
    ];
    const featureListData = [
      {
        title: 'Discount on Lab Test',
        subTitle:
          'Get access to all radiology and pathology test, and enjoy the heavy discounted rates.',
        iconUrl: 'images/icons/lab.svg',
      },
      {
        title: 'Discount on Medicines',
        subTitle:
          'We made expensive medicines, affordable and accessible. Just think of the cure not the cost.',
        iconUrl: 'images/icons/medicine.svg',
      },
      {
        title: 'Free Tele Consultation',
        subTitle:
          'Get free tele consultation from the experienced and reputed doctors. Never worry about the fees.',
        iconUrl: 'images/icons/consulatation.svg',
      },
      {
        title: 'Interest free medical loans',
        subTitle:
          'Ran out of budget! Need more help? Well, we got you covered. Get interest free medical loan of up to 1 lac for 3 months instantly.',
        iconUrl: 'images/icons/loan.svg',
      },
    ];
    

    export default function Opd ({btnText,
    
    }){
            pageTitle('Opd-package | ');
  return (
    <div>
    <BannerSectionStyle3
    bgUrl="/images/pricing_plan/banner_bg.svg"
    imgUrl="/images/pricing_plan/banner_img.png"
    title="Find the Right OPD Plan for You"
    subTitle="Explore Our Membership Options and Start Your Journey to Better Health"
    btnText="Get Started"
    btnUrl="/contact"
  />
 
<Section
        className="cs_bg_filed"

        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <PricingSection
          sectionTitle="Plans made just for you."
          sectionTitleUp="OUR PLANS"
          data={pricingData}
          btnText="Get Started"
          btnUrl="/opd2"
        />
          {/* <div className="dark-button-container d-flex justify-content-center align-items-center mt-3">
      <button  className="btn btn-dark rounded-pill ">Compare Plans</button>
    </div> */}
        
      </Section>
      <Section bottomMd={125} bottomLg={125} bottomXl={85} topMd={185}
        topLg={140}
        topXl={100}>
        <DepartmentSectionStyle4

          sectionTitle="We understand the need"
          sectionTitleUp="Members"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="What we are offering?"
          imgUrl="images/about/offering.png"
          data={featureListData}
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Frequently Asked <br />Questions"
          data={faqData}
          faqImgUrl="/images/home_4/faq_img.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section>
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Searching OPD plans for your business!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
          btnText="Get Started"
          btnUrl="/opd2"
          
          />
          
          
       
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={brandData} />
      </Section>
   
</div>
  )
}
